import React from 'react';
import './style/About.css';

function About() {
  return (
    <section id="about" className="about">
      <h2>About Me</h2>
      <p>Welcome to my portfolio website. My name is Kristian Shneltser and I am a system developer with a passion for creating innovative solutions...</p>
    </section>
  );
}

export default About;
